import React from "react"
import * as styles from "./global.module.scss"
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"
import Footer from "../components/Homepage/Footer/Footer"
import { Helmet } from "react-helmet"
import GoogleTagManager from "../components/GoogleTagManager/GoogleTagManager"

const getImage = graphql`
  {
    image: file(relativePath: { eq: "gdpr.webp" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default function Gdpr() {
  const { image } = useStaticQuery(getImage)
  return (
    <div>
      <GoogleTagManager />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Competdac - GDPR</title>
        <meta
          name="description"
          content="General Data Protection Regulations and how we are using your data"
        ></meta>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0"
        ></meta>
      </Helmet>
      <div className={styles.container}>
        <Image fluid={image.childImageSharp.fluid} alt="GDPR"></Image>
        <div class={styles.entry_content}>
          <h1
            style={{
              fontSize: "28px",
              textAlign: "center",
              marginBottom: "7%",
            }}
          >
            <span lang="en">PERSONAL DATA PRIVACY POLICY- GDPR</span>
          </h1>
          <p>
            <strong> </strong>
          </p>
          <p>
            <strong>Terminology</strong>
          </p>
          <ul>
            <li>
              <strong>’employee (s)’</strong> – any person who is or has been
              employed or apply for employment at COMPETDAC, including
              administrative, self-employed or under collaboration contract,
              secondary or temporary staff, volunteer, retired, including legal
              representatives ;
            </li>
            <li>
              <strong>‘personal data’</strong> – information related to an
              identified or identifiable natural person (‘person/subject’); an
              identifiable person is one that can be identified, directly or
              indirectly, in particular by reference to an identifier such as a
              name, personal identification number/personal identification
              number, location data, online identification data or one or more
              specific factors who can identify that person physically,
              physiologically, genetically, mentally, economically, culturally
              or socially;
            </li>
            <li>
              <strong>‘sensitive personal data’</strong> – personal data related
              to race or ethnic origin, political, religious or philosophical
              beliefs, membership of certain groups or unions, unique genetic
              and biometric data of an individual being processed for the
              purpose of identification, data on a person’s health , sexual life
              or sexual orientation;
            </li>
            <li>
              <strong>‘processing ‘ – </strong> any operation or set of
              operations applied to personal data or a set of personal data,
              whether or not by automatic means such as: collecting, recording,
              organizing, structuring, storing, adapting or modifying them,
              retrieving, consulting, using, disclosing by transmission,
              dissemination or other method by which they become available,
              aligning, comparing or combining these data, limiting/restricting
              them, deleting or destroying them;
            </li>
            <li>
              <strong>‘limitation/restriction of processing’</strong> – the
              marking of personal data stored in order to limit their processing
              in the future;
            </li>
            <li>
              <strong>‘ profiling’</strong> – any automatic processing of
              personal data consisting in the use of such data to assess certain
              personal aspects relating to a particular individual, in
              particular to analyze or predict/estimate certain aspects of
              performance at the place of work, economic situation, health
              status, personal preferences, interests, reliability, behavior,
              location or movements/moves;
            </li>
            <li>
              <strong>‘archiving system’</strong> – any set of structured
              personal data that is available on a specific basis, whether in a
              centralized or non-centralized form, functionally or
              geographically dispersed;
            </li>
            <li>
              <strong>‘controller, inspector or control person’</strong> – a
              natural or legal person, public authority, agency or body which
              independently or together with others determines the purposes and
              means of the processing of personal data; if the purposes and
              means of such processing are required by the applicable
              legislation, the specific criteria mentioned by them may be
              provided by the laws and regulations in force;
            </li>
            <li>
              <strong>‘processor’</strong> – a natural or legal person, public
              authority, agency or other body that processes personal data for
              the benefit of a controlling person;
            </li>
            <li>
              <strong>‘recipient’</strong> – a natural or legal person, public
              authority, agency or other body to which personal data are
              sent/disclosed, whether or not a third party. However, public
              authorities that may receive personal data in a manner that is
              required by law, do not have to be considered as recipients; the
              processing of data by these authorities must be in accordance with
              the data protection rules in force in accordance with the purposes
              of the processing;
            </li>
            <li>
              <strong>‘third party’</strong> – a natural or legal person, public
              authority, agency or body other than the owner of the personal
              data, control person, processor or other persons who, under the
              direct guidance of a control or processor, are authorized to
              process the personal data;
            </li>
            <li>
              <strong>‘consent’</strong> – any indication, freely accept,
              specific, informed and unambiguous about the desires of the person
              concerned, by which he or she, by affirmation or affirmative
              action, signifies the consent of that person to the processing of
              his or her personal data;
            </li>
            <li>
              <strong>‘personal data breach’</strong> – a breach in data
              security that may result in accidental or unlawful destruction,
              loss, alteration, unauthorized disclosure of data transmitted,
              stored or otherwise processed, or access to them;
            </li>
            <li>
              <strong>‘biometric data’</strong> – personal data resulting from
              technical processing techniques relating to the physical,
              physiological or behavioral characteristics of a natural person
              that allow access to or confirm the unique identification of that
              person, such as facial images or fingerprints;
            </li>
            <li>
              <strong>‘representative’</strong> – a natural or legal person
              appointed by the person in charge of control or by the processor,
              in accordance with Article 27, to represent them in connection
              with their obligations under this Regulation;
            </li>
            <li>
              <strong>‘international organization’</strong> – an organization
              and its subordinates governed by international public law, or any
              other body that is established by or on the basis of an agreement
              between two or more countries.
            </li>
          </ul>
          <p
            id="tw-target-text"
            class="tw-data-text tw-ta tw-text-medium"
            dir="ltr"
            data-placeholder="Traducere"
          >
            <strong>
              <span lang="en">Abbreviations used</span>
            </strong>
          </p>
          <ul>
            <li>
              <strong>DPO</strong> – Data Protection Officer;
            </li>
            <li>
              <strong>GDPR</strong> – Regulation (EU) 2016/679 of the European
              Parliament and of the European Council on the protection of
              individuals with regard to the processing of personal data of the
              free movement, transfers of such data and the use of appropriate
              practices;
            </li>
            <li>
              <strong>EEA</strong> – European Economic Area;
            </li>
            <li>
              <strong>EU –</strong> European Union.
            </li>
          </ul>
          <p>&nbsp;</p>
          <h2>1. Introduction</h2>
          <p
            id="tw-target-text"
            class="tw-data-text tw-ta tw-text-small"
            dir="ltr"
            data-placeholder="Traducere"
          >
            <span lang="en">
              COMPETDAC or the “Organization”, by nature of the activities
              carried out, at one point may collect and use certain personal
              data from individuals who can provide service or products, or
              their employees, other business partners or their employees,
              business contacts, employees, or other people with whom the
              Organization has a particular relationship or contacts.
            </span>
          </p>
          <div id="tw-target-text-container" class="tw-ta-container tw-nfl">
            <p
              id="tw-target-text"
              class="tw-data-text tw-ta tw-text-small"
              dir="ltr"
              data-placeholder="Traducere"
            >
              <span lang="en">
                Personal Data Privacy Policy, here called “Policy”, defines how
                COMPETDAC collects personal data, uses, stores, and processes it
                in any way. This policy complies with GDPR and applies with any
                other data protection policies and procedures developed and
                implemented by COMPETDAC.
              </span>
            </p>
            <p class="tw-data-text tw-ta tw-text-small" lang="en">
              This Policy reffers and covers only the personal data of
              individuals who are our employees, our clients or their employees,
              our suppliers or our business partners and their employees, as
              individuals. The policy does not apply to legal
              clients(companies), corporate clients, vendors or other business
              partners or public authorities, unless the legislation in force
              stipulates otherwise.
            </p>
          </div>
          <h3>
            1.1 <span lang="en">Objective</span>
          </h3>
          <p>
            This policy aims to provide a general framework for ensuring an
            adequate level of protection of the personal data of employees,
            clients, contractual partners and their employees/representatives or
            public authorities, processed by <span lang="en">COMPETDAC</span>.
          </p>
          <p
            id="tw-target-text"
            class="tw-data-text tw-ta tw-text-small"
            dir="ltr"
            data-placeholder="Traducere"
          >
            <span lang="en">
              In addition, the Policy provides instructions for COMPETDAC to be
              able to do the following:
            </span>
          </p>
          <p>
            a) Comply with data protection legislation, including the GDPR, and
            follow the right practices in this respect
          </p>
          <p>
            b){" "}
            <span lang="en">
              Protect the rights of employees, contractual partners and
              employees/their representatives and employees/representatives of
              public authorities
            </span>
          </p>
          <p>
            c) Ensure transparency about how they store and process personal
            data;
          </p>
          <p>
            d) To prepare and implement appropriate safety measures to protect
            themselves and the persons whose data are processed, from the risks
            associated with such activity, such as:
          </p>
          <ul>
            <li>
              <strong>Non-compliance with confidentiality.</strong> For example,
              disclosure of personal data to a third party due to the lack or
              ineffectiveness of a security control measure, or due to an
              inappropriate disclosure/transmission way of information
            </li>
            <li>
              <span lang="en">
                <strong>Damage to reputation.</strong> For example, COMPETDAC
                reputation may suffer if unauthorized persons obtain access to
                sensitive data, or certain people may be affected by how the
                Organization uses their personal data;
              </span>
            </li>
          </ul>
          <p>
            In order to prevent and reduce the risk of collecting and processing
            personal data, it is mandatory for all personnel who have access to
            any type of personal data to ensure that their actions comply with
            the instructions set forth in this Policy.
          </p>
          <div id="tw-target-text-container" class="tw-ta-container tw-nfl">
            <p
              id="tw-target-text"
              class="tw-data-text tw-ta tw-text-small"
              data-placeholder="Traducere"
            >
              <span lang="en">
                This Policy will be communicated to all employees, clients,
                suppliers and contract partners by all means available at all
                levels of COMPETDAC, including by e-mail.
              </span>
            </p>
          </div>
          <h3>1.2 Scope/area of application</h3>
          <p>This policy is mandatory for:</p>
          <p>
            a) <span lang="en">All employees COMPETDAC;</span>
          </p>
          <p>
            b) All contractors, suppliers, clients and their employees, as well
            as other people who process the data on behalf of{" "}
            <span lang="en">COMPETDAC</span>, in the context of the services
            provided by <span lang="en">COMPETDAC</span>.
          </p>
          <div id="tw-target-text-container" class="tw-ta-container tw-nfl">
            <p
              id="tw-target-text"
              class="tw-data-text tw-ta tw-text-small"
              data-placeholder="Traducere"
            >
              <span lang="en">
                The policy sets out rules for the processing of personal data
                belonging to:
              </span>
            </p>
          </div>
          <p>
            a) Employees <span lang="en">COMPETDAC</span>;
          </p>
          <p>
            b){" "}
            <span lang="en">
              Employees/representatives of all contractual partners of COMPETDAC
              and public authorities;
            </span>
          </p>
          <p
            id="tw-target-text"
            class="tw-data-text tw-ta tw-text-medium"
            dir="ltr"
            data-placeholder="Traducere"
          >
            <strong>
              <span lang="en">
                Employee data includes, but is not limited to:
              </span>
            </strong>
          </p>
          <ul>
            <li>
              Employee identification data, education (example: name, first
              name, home address, birthdate, family and marital status, serial
              and ID card or passport number, phone number, photos, personal
              numeric code, diplomas and certificates education, visas and other
              residence permits, driving license number);
            </li>
            <li>
              Current employee hiring history and employment (eg: certificates
              or recommendations issued by previous employees, data included in
              curriculum vitae, cover letters, previous salary data,
              compensatory amounts, promotions, awards, diplomas and benefits,
              disciplinary sanctions , performance appraisals, feedback from
              employers, absentee records, registration numbers of service cars;
            </li>
            <li>
              Data about employee’s training courses, professional certificates,
              list of skills (including industry specializations)
            </li>
            <li>Time and expenses of employees;</li>
            <li>
              Employee insurance information (medical insurance, medical
              certificates, travel insurance, retirement benefits and others);
            </li>
            <li>
              Financial information of the employees (number/IBAN code of their
              bank account, pension funds, investments);
            </li>
            <li>
              Information included in employee e-mail, telephone conversations,
              and other information provided by IT departments of{" "}
              <span lang="en">COMPETDAC</span>.
            </li>
          </ul>
          <p>
            Where applicable, <span lang="en">COMPETDAC</span> can process your
            employees’ personal data on the basis of one of the following
            regulations:
          </p>
          <p>
            I. Data is required to respond to a potential hiring/future
            employment contract (recruitment) or to assess the performance of a
            work contract;
          </p>
          <p>
            II. The data are required for <span lang="en">COMPETDAC</span> to
            comply with legal requirements (eg social contributions to the
            state, social security, social protection obligations);
          </p>
          <p>
            III. The data are necessary for the legitimate interests of{" "}
            <span lang="en">COMPETDAC</span> that go beyond the interests or
            fundamental rights and freedom of the person requiring the
            protection of personal data;
          </p>
          <p>IV. Consent of the employee.</p>
          <p>
            <strong>
              The data of the contractual partners or their employees, include
              but are not limited to:
            </strong>
          </p>
          <ul>
            <li>
              Contact data (name, surname, address, phone number, e-mail
              address, job position, signature);
            </li>
            <li>
              Any other data required by the legislation in force for the
              performance of a contract.
            </li>
          </ul>
          <p>
            Where applicable, <span lang="en">COMPETDAC</span> collects personal
            data by virtue of legal bases such as:
          </p>
          <ul>
            <li>Data is required for a contract (services supply);</li>
            <li>
              The data are necessary to comply with the legal obligations of{" "}
              <span lang="en">COMPETDAC</span> (reporting obligations imposed by
              the legislation in force);
            </li>
            <li>
              Data is required for legitimate interests of{" "}
              <span lang="en">COMPETDAC</span> that exceed the interests or
              fundamental rights;
            </li>
            <li>Customer Consent (eg direct marketing).</li>
          </ul>
          <p>
            <em>
              For a complete list of personal data collected and processed by{" "}
              <span lang="en">COMPETDAC</span>, please see{" "}
              <a
                href="https://www.competdac.ro/en/politica-de-prelucrare-a-datelor"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.competdac.ro
              </a>
            </em>
          </p>
          <p>
            This Policy was adopted by the Executive Board of{" "}
            <span lang="en">COMPETDAC</span> and is implemented starting with
            May 25, 2018 (Effective Date) and will be available for consultation
            by employees and business partners at their request.
          </p>
          <p>
            This Policy will only apply where it does not conflict with
            applicable law. In cases where the legislation in force provides
            more protection than this policy, local laws prevail.
          </p>
          <p>&nbsp;</p>
          <h2>2. Rules for processing personal data</h2>
          <p>
            <span lang="en">COMPETDAC</span> is commited to comply with GDPR
            principles. These priciples are:
          </p>
          <p>
            <strong>Equity, justice and transparency:</strong> this means that{" "}
            <span lang="en">COMPETDAC</span> must a have a legitimate ground to
            process personal data.
          </p>
          <p>
            <span lang="en">COMPETDAC</span> can process personal data only
            based on GDPR instructions,as follows:
          </p>
          <ul>
            <li>
              The explicit and unambiguous consent of the person to whom the
              data belong;
            </li>
            <li>
              Processing is required to run a contract (for example, a work
              contract) or to respond to a person’s request to enter into a
              contract (eg analyzing a CV sent by a candidate applying for a
              specific position at <span lang="en">COMPETDAC</span>);
            </li>
            <li>
              Processing is required to comply with a{" "}
              <span lang="en">COMPETDAC</span> obligation (prepare spreadsheets
              for drivers’ permits to access military bases);
            </li>
            <li>
              Processing is necessary for the legitimate purposes of{" "}
              <span lang="en">COMPETDAC</span>; in any case, if any damage will
              occur to the right, freedom and legitimate interests of the person
              concerned, <span lang="en">COMPETDAC</span> will not start
              processing solely for the interests of the firm or a third party.
            </li>
          </ul>
          <p>
            In addition, data processing should not oppose no applicable law.
            Moreover, <span lang="en">COMPETDAC</span> must inform the person
            concerned about the processing of his or her data in an accessible
            and easy to understand manner.
          </p>
          <p>
            <strong>Data processing limitation:</strong>{" "}
            <span lang="en">COMPETDAC</span> will only collect strictly personal
            data that is necessary for specific, explicit and legitimate
            purposes, and will not process any additional data beyond the scope.
          </p>
          <p>
            <span lang="en">COMPETDAC</span> must clearly indicate the purpose
            for which it processes the personal data and that purpose must not
            oppose applicable law. If <span lang="en">COMPETDAC</span> intends
            to use this data for purposes other than stated, it must start in
            advance all the necessary formalities for processing for the new
            purpose.
          </p>
          <p>
            <strong>Minimizing processing of personal data:</strong> Personal
            data must be processed appropriately, relevant and limited only to
            what is specifically related to the purpose; this means{" "}
            <span lang="en">COMPETDAC</span> does not have to process data that
            is not necessary for that purpose.
          </p>
          <p>
            <strong>Data Accuracy: </strong> <span lang="en">COMPETDAC</span>{" "}
            has the obligation to ensure that the requested and collected
            personal data are correct, accurate and up-to-date;{" "}
            <span lang="en">COMPETDAC</span> has the duty to ensure that
            inaccurate data is erased or rectified immediately.
          </p>
          <p>
            <strong>Limited storage: </strong> <span lang="en">COMPETDAC</span>{" "}
            will not retain your personal data for a longer period than is
            necessary for the purpose for which it was collected and then
            processed.
          </p>
          <p>
            <strong>Integrity and Confidentiality: </strong>{" "}
            <span lang="en">COMPETDAC</span> must take safety and control
            measures to provide protection against unauthorized and unlawful
            processing, loss of or accidental destruction of personal data,
            alteration of such data. This involves both technical and
            organizational measures, defined processes, training and
            information.
          </p>
          <p>
            <strong>Legal transfer of data outside the EEA: </strong>{" "}
            <span lang="en">COMPETDAC</span> transfers personal data outside the
            EEA only to countries recognized by the EU Commission, providing an
            adequate level of data protection or to countries that have security
            measures in place such as the appropriate contractual framework (eg:
            standard contractual clauses adopted by the EU Commission).
          </p>
          <p>
            <strong>Individual Rights:</strong> Individuals have rights to be
            respected by <span lang="en">COMPETDAC</span>, such as the right to
            access a copy of <span lang="en">COMPETDAC</span>’s personal data
            about them, the right to opt out of direct marketing campaigns to
            which they have joined previously.
          </p>
          <p>
            Both <span lang="en">COMPETDAC</span> and any of the processors must
            ensure the confidentiality of personal data in accordance with legal
            regulations. They will not disclose in any way or publish any
            information related to personal data or processing operations
            without the consent of the person concerned, except in cases where{" "}
            <span lang="en">COMPETDAC</span> or the respective processor acts as
            a result of a legal obligation or under the legal provisions.
          </p>
          <p>
            <span lang="en">COMPETDAC</span> ensures the confidentiality,
            integrity and availability of information in electronic and printed
            form.
          </p>
          <p>
            In the event of loss, suspicion of potential loss or leak of
            personal data to unauthorized persons,{" "}
            <span lang="en">COMPETDAC</span> will inform the competent
            authorities in accordance with the legal requirements.
          </p>
          <p>
            <span lang="en">COMPETDAC</span> will maintain data security by
            protecting the confidentiality, integrity and availability of
            personal data, defining them as follows:
          </p>
          <ul>
            <li>
              Privacy: Protect personal data from unauthorized disclosure or
              interception;
            </li>
            <li>
              Integrity: protection of the accuracy, completeness and updated
              personal data from IT systems/computer software that
              stores/processes personal data;
            </li>
            <li>
              Availability: Ensure that personal data is accessible to
              authorized users when requested.
            </li>
          </ul>
          <h2>2.1 Processing of sensitive data</h2>
          <p>
            <span lang="en">COMPETDAC</span> forbids the processing of data
            related to race or ethnic origin, political opinions, religious or
            philosophical beliefs, physical or mental health data, trade union
            membership, biometric data, or data processing related to sanitation
            or sexual life, with the exception of:
          </p>
          <ul>
            <li>
              The individual has explicitly given his/her consent for processing
              of such data, unless the law provides that such consent can not
              justify processing in the given situation;
            </li>
            <li>
              The law expressly states processing to protect an important public
              interest, if all rights related to personal data are correctly
              observed;
            </li>
            <li>
              Processing is necessary for establishing, exercising or defending
              a legal right in the court;
            </li>
            <li>
              Processing refers to data that is made public by the person
              concerned;
            </li>
            <li>
              Processing is necessary to fulfill the specific obligations and
              rights of the Organization in the field of labor law, social
              security and social protection, insofar as it is authorized by law
              or collective agreement in accordance with the law.
            </li>
          </ul>
          <h2>2.2 The Consent</h2>
          <p>
            Consent is defined in Article 4 (11) of the GDPR as: “A free,
            specific indication, based on information and without ambiguous
            indications, of the person concerned about his or her desires, by
            affirmative affirmation or affirmative action clear,
            represents/signifies the given agreement for the processing of
            personal data related to that person”.
          </p>
          <h2>
            <strong>2.2.1 Principles</strong>
          </h2>
          <p>
            <strong>Consent must be free:</strong> consent must reflect the free
            and genuine will of the person in question without any constraint or
            pressure on the person, avoiding any negative consequences in case
            of refusal.
          </p>
          <p>
            <strong>The consent must be specific:</strong>{" "}
            <span lang="en">COMPETDAC</span> must clearly and accurately explain
            the purpose and consequences of processing the data for which
            consent is required. More consensus must cover all the activities of
            the process conducted for the same purpose or purposes for which
            each consent is required.
          </p>
          <p>
            <strong>Consent must be aknowledge:</strong> the nature of the
            processing must be explained in an intelligible and easily
            accessible form, using clear and simple language that does not
            contain unknown terms. The person concerned must be informed at
            least of the issues below, so that his/her consent is considered to
            have given his informed consent:
          </p>
          <ul>
            <li>
              The identity of the <span lang="en">COMPETDAC</span> entity that
              acts as a data control body;
            </li>
            <li>The purpose for which data needs to be processed;</li>
            <li>The type of data to process;</li>
            <li>Right to withdraw consent;</li>
            <li>
              If applicable, the fact that the data will be used for automated
              decision-making processes, processes with legal effects on the
              subject or that significantly affect it;
            </li>
            <li>
              Possible risks of data transfer outside of the EU/EEA due to the
              absence of a suitable Security decision or measure.
            </li>
          </ul>
          <p>
            <strong>Consent must be explicit:</strong>{" "}
            <span lang="en">COMPETDAC</span> uses written forms that are
            completed with the data and only e-mail responses.
          </p>
          <h2>
            <strong>2.2.2 Individual explicit consent</strong>
          </h2>
          <p>
            Explicit consent is required only if{" "}
            <span lang="en">COMPETDAC</span> processes sensitive categories of
            data (eg medical, genetic, biometric, racial or ethical, political,
            religious or philosophical beliefs, data on physical and mental
            health, trade union membership, health data, or sex life).
          </p>
          <p>
            <span lang="en">COMPETDAC</span> is not collecting this type of
            data.
          </p>
          <h2>
            <strong>2.2.3 Registration of consent</strong>
          </h2>
          <p>
            <span lang="en">COMPETDAC</span> must keep recordings of the consent
            to prove is valid and subject was aware about purpose of collecting.
          </p>
          <p>
            In case of personal data of partners’/suppliers’/carriers’
            employees, drivers/equipments’ manipulators, collected in order to
            obtain their permit to access military bases,{" "}
            <span lang="en">COMPETDAC </span>is transffering responsability to
            their employers and consider a valid consent of these persons when
            that employer is sending, by e-mail or sms, personal data that
            belongs to that specific employee, who will perform the requested
            service (for which the base access is necessary). Parteners were
            informed by e-mail about this Policy and is their responsability to
            inform and obtain their employees’ consent.
          </p>
          <h2>
            <strong>2.2.4 Direct Marketing</strong>
          </h2>
          <p>
            <span lang="en">COMPETDAC</span> will engage an unsolicited
            commercial communication (direct marketing communication) only upon
            the consent of the person concerned to receive such messages. In any
            direct marketing communication addressed to an individual, this
            should be given the opportunity to give up receiving such messages.
            The personal data collected by <span lang="en">COMPETDAC</span> will
            never be disclosed to a third party company that intends to use them
            for direct marketing unless the person in question has specifically
            given this consent.
          </p>
          <p>
            <strong>Opposition to direct marketing</strong>
          </p>
          <p>
            If a person refuses to receive direct marketing messages or
            withdraws his prior consent to receive such material,{" "}
            <span lang="en">COMPETDAC</span> will respond to this request and
            will no longer send such material in the future. Opposition to
            direct marketing applies only to the extent that direct marketing
            takes place on the legitimate interests of{" "}
            <span lang="en">COMPETDAC</span>. It does not apply to situations
            where direct marketing is based on the consent of that person (see
            2.2.6 below), in which case withdrawal of consent is the way a
            person can request to stop marketing communication.
          </p>
          <p>
            When direct marketing communications are sent to a person who has
            given their consent to this type of message but this person sends a
            waiver request to continue receiving these messages,{" "}
            <span lang="en">COMPETDAC</span> must restrict the use of the
            subject’s contact details, for direct marketing, until clarification
            is obtained from that person, even if the notification received from
            him represents or not the withdrawal of the consent.
          </p>
          <h2>
            <strong>2.2.5 Withdrawal of consent</strong>
          </h2>
          <p>
            A natural person has the right to withdraw his consent at any time,
            at no cost. Withdrawal must not affect the lawfulness of the
            processing based on this consent prior to its withdrawal. Prior to
            giving consent, the subject should be informed about it. It must be
            as simple to withdraw consent as when it was given.{" "}
            <span lang="en">COMPETDAC</span> has the obligation to make this
            process as simple as possible.
          </p>
          <p>
            <span lang="en">COMPETDAC</span> accepts written statements signed
            by the person, specifying the right to withdraw a consent. This
            statement should be sent to{" "}
            <a
              href="mailto:contact@competdac.ro"
              target="_blank"
              rel="noopener noreferrer"
            >
              contact@competdac.ro
            </a>{" "}
            or by mail using the Organization’s address on its website.
          </p>
          <p>
            Validation of the withdrawal of consent will be made by the
            following means of communication: e-mail, in written form completed
            or online. In addition, <span lang="en">COMPETDAC</span> keeps
            records to demonstrate that a valid consent has been given and that
            the subject concerned has been informed in advance.
          </p>
          <h2>2.3 Notification</h2>
          <p>
            <span lang="en">COMPETDAC</span> must inform individuals through a
            data protection policy or notification about:
          </p>
          <ul>
            <li>Business reason for which their data is processed;</li>
            <li>
              Any other relevant information (e.g., the nature and category of
              the processed data, the third party category to which these data
              are disclosed, if any, how they can exercise their rights, the
              legal bases of data processing, etc.).
            </li>
          </ul>
          <p>
            For this purpose, <span lang="en">COMPETDAC</span> sends information
            to individuals or their employers in various forms, depending on how
            they interact with them, including: (i) statements posted on the
            Organization’s website; (ii) emails to the contractual
            partner/service provider of which the employee is that person and
            who sends his data to the Organization.
          </p>
          <p>&nbsp;</p>
          <h2>2.4 Using data</h2>
          <p>
            Personal data can only be useful if they can be used. When accessing
            and using this data, there may be risks of loss, destruction or
            theft of the data, so:
          </p>
          <ul>
            <li>
              Advertisers must ensure that computer screens are permanently
              password-protected and locked when left unattended;
            </li>
            <li>There is no informal exchange of personal data;</li>
            <li>
              Personal data must be encrypted before electronic transfer. The IT
              Department may explain the safe forwarding of data to third
              parties;
            </li>
            <li>
              Employees should not save copies of this data on their personal
              computers;
            </li>
            <li>
              Personal data must not be disclosed to unauthorized persons,
              organizations or outside of the organization;
            </li>
            <li>
              Personal data should be periodically reviewed and updated if
              necessary. If they are no longer needed, they must be deleted and
              removed;
            </li>
            <li>
              Employees should seek support from the general manager if they are
              unsure of certain aspects of data protection;
            </li>
            <li>
              The only people who want to access the data included in this
              policy are those who need it in their daily work;
            </li>
            <li>
              Personal data is distributed in a formal manner, when access to
              confidential information is requested, the employees can request
              them from the general manager.
            </li>
          </ul>
          <p>
            <span lang="en">COMPETDAC</span> will provide training to all
            employees to make them aware of the responsibilities they have when
            they come in contact with personal data and the provisions of this
            Policy.
          </p>
          <h2>2.5 Data storage</h2>
          <p>
            Personal data must be stored in a way that makes it possible to
            identify the persons to whom they belong no more than necessary for
            the purpose for which they were collected. These data may be stored
            as long as they are processed for archiving purposes for public,
            scientific, historical, and statistical interstitial implementation
            with the implementation of appropriate safety measures.
          </p>
          <p>
            Data storage is the operation that keeps personal data collected by{" "}
            <span lang="en">COMPETDAC</span> on any support (electronic or
            hardcopy).
          </p>
          <p>
            Personal data must be processed in such a way as to ensure their
            security, including protection against unauthorized or unlawful
            processing, accidental loss, destruction, or inappropriate use of
            technical and organizational measures.
          </p>
          <p>Security measures for data collected on written documents:</p>
          <ul>
            <li>
              Users who are using written documents containing personal data
              must lock and secure the offices and storage of this information
              when they are not in the office;
            </li>
            <li>
              <span lang="en">COMPETDAC</span> will set up retention or removal
              programs for specific categories of records to ensure legal
              compliance and other purposes such as intellectual property
              preservation and cost management.
            </li>
          </ul>
          <p>&nbsp;</p>
          <h2>2.6 Personal data accuracy</h2>
          <p>
            Personal data must be accurate and, where appropriate, updated. Any
            step is necessary to ensure that any incorrect data is deleted or
            rectified immediately. It is the responsibility of all employees
            using personal data to take reasonable steps to preserve their
            accuracy and to update them as much as possible.
          </p>
          <p>Steps/good practices:</p>
          <ul>
            <li>
              Personal data will be stored in as few places as possible,{" "}
              <span lang="en">COMPETDAC</span> staff should not create
              unnecessary additional sets;
            </li>
            <li>
              <span lang="en">COMPETDAC</span> staff must use any opportunity to
              update the data;
            </li>
            <li>
              <span lang="en">COMPETDAC</span> will provide easy access for
              individuals to their personal data;
            </li>
            <li>Any inaccuracies must be corrected as soon as discovered.</li>
          </ul>
          <h2>3. Responsibilities</h2>
          <p>
            Any person authorized by <span lang="en">COMPETDAC</span> to process
            personal data in his favor and his employees who is involved in such
            processing, or who has access to personal data in any way, is bound
            to comply with this Policy.
          </p>
          <p>
            All <span lang="en">COMPETDAC</span> employees have responsibilities
            in collecting, processing and storing personal data in a correct
            way.
          </p>
          <p>
            <strong>
              According to the regulations in force, the Organization does not
              need to implement a Data Protection Officer (DPO).
            </strong>
          </p>
          <p>
            <strong> </strong>
            <strong>Employees with access to personal data</strong>
          </p>
          <p>Their responsibilities are, but are not limited to:</p>
          <ul>
            <li>
              Access only those personal data that strictly serve the purpose
              for which they are processed;
            </li>
            <li>
              Report any potential incident related to these data to the general
              manager;
            </li>
            <li>
              Never discuss confidential information in public or with people
              who do not need to know them;
            </li>
            <li>Store any documents with personal data in a proper way;</li>
            <li>
              Computers must be shut down completely when they are not used for
              a long time (after schedule, weekends, holidays, etc);
            </li>
            <li>
              Lock and secure information and equipment when not in the office;
            </li>
            <li>
              Users must keep offices in order and organized, keep personal and
              secured personal documents out of sight when they are out of
              office;
            </li>
            <li>Not to disclose passwords;</li>
            <li>Report any security violations brought to their attention;</li>
            <li>
              To consult the general manager whenever they have a personal
              concern/suspicion.
            </li>
          </ul>
          <p>
            The top management of <span lang="en">COMPETDAC</span> has
            responsibilities, but is not limited to:
          </p>
          <ul>
            <li>
              Establish regular and transparent reporting and reporting
              mechanisms to monitor major risks, including risks related to
              non-compliance with this Policy;
            </li>
            <li>
              To approve and periodically review, at least annually, this
              Policy, based on proposals from the responsible units of the
              various units.
            </li>
            <li>
              Ensure that there is a proper organizational structure, effective
              communication and well-established reporting channels to ensure
              that personal data is processed in a clear and consistent manner,
              in accordance with <span lang="en">COMPETDAC</span> policies and
              procedures.
            </li>
            <li>
              Ensure effective implementation of the required business
              management framework, including the establishment of mechanisms to
              develop and monitor internal regulations to ensure that this
              Policy is properly implemented.
            </li>
            <li>
              Ensure that all systems, services and equipment used to
              process/store personal data meet acceptable security standards;
            </li>
            <li>
              To conduct periodic checks and scans to ensure the operation and
              security of hardware and software;
            </li>
            <li>
              Evaluate any third-party service that the Organization considers
              to be used to store or process personal data to ensure integrity,
              confidentiality and availability (eg cloud providers);
            </li>
            <li>
              Identify and measure technical measures to ensure the security of
              stored data;
            </li>
            <li>
              Provide support to investigate potential breaches in security;
            </li>
            <li>
              Provide technical training and security standards for the
              processing and protection of personal data to{" "}
              <span lang="en">COMPETDAC</span> personnel.
            </li>
            <li>
              Ensure that their business unit processes personal data in
              accordance with this Policy;
            </li>
            <li>
              Ensure that <span lang="en">COMPETDAC</span> personnel are
              informed about the relevant policies and standards in this Policy;
            </li>
            <li>
              Identify staff training and development needs in connection with
              processing and protection of personal data;
            </li>
            <li>
              Ensure that any action taken on employees’ data is in accordance
              with GDPR regulations. This applies to all processes managed by
              the human resources team, starting with the process of recruiting,
              implementing and terminating the employment contract, ensuring a
              balance between the interests of the company and the right to
              private life of the employees.
            </li>
          </ul>
          <p>&nbsp;</p>
          <h2>4 Rights of individuals</h2>
          <p>&nbsp;</p>
          <h2>4.1 Right to be informed</h2>
          <p>
            In the process of collecting the data of its employees or business
            partners/service providers or in any other situation that derives
            from them, <span lang="en">COMPETDAC</span> ensures that these
            persons are brought to their attention as follows:
          </p>
          <ul>
            <li>The purpose and legal basis of the processing;</li>
            <li>Legitimate interests;</li>
            <li>
              Recipient or categories of recipients of personal data, if
              applicable;
            </li>
            <li>
              Intention to transfer personal data to a third country or
              international organization and the existence or absence of an
              appropriate decision or, where applicable, the necessary security
              measures for transfer and the means of obtaining copies of these
              security measures;
            </li>
            <li>
              The period of personal data storage or the criteria that determine
              this period;
            </li>
            <li>
              Existence of the right of the person concerned: to request access
              for correction or deletion of personal data, restriction of
              processing or opposition to processing, portability, withdrawal of
              consent at any time, right to send a complaint to a higher
              authority;
            </li>
            <li>
              Where appropriate, the existence of an automatic decision-making
              process, including profiling, information about the logic
              involved, and the significance and consequences of that process on
              the person concerned;
            </li>
            <li>
              Whether the provision of personal data is statutory or contractual
              requirements, or a need to initiate a contract, informs you of the
              person’s obligation to provide personal information and the
              consequences in the event of failure to submit them.
            </li>
          </ul>
          <p>
            The above mentioned information must also be provided in cases where
            the data were not obtained directly from the person to whom they
            belong, along with the following additional information:
          </p>
          <ul>
            <li>Category of processed data;</li>
            <li>From what source the provided data comes from;</li>
            <li>If applicable, if the data comes from public sources.</li>
          </ul>
          <p>
            When personal data are not obtained directly from the person
            concerned, the information has to be provided within a reasonable
            time after the data is obtained (maximum one month) or at the
            earliest on the first communication with the person concerned or
            when the personal data are sent to a another hired recipe.
          </p>
          <p>
            <span lang="en">COMPETDAC</span> will not provide processing
            information if it is considered to be the case here
            <br />
            it is possible to seriously compromise the achievement of the
            objectives (eg prevention, investigation, breach detection in
            professional ethics or criminal offenses).
          </p>
          <h2>4.2 Right of access</h2>
          <p>
            <span lang="en">COMPETDAC</span> will take appropriate steps to
            provide processing information to the person concerned in a concise,
            transparent, comprehensible and easily accessible way, using clear
            and simple language.
          </p>
          <p>
            Individuals have the right to confirm whether{" "}
            <span lang="en">COMPETDAC</span> really processes their personal
            data. If the answer is yes, <span lang="en">COMPETDAC</span> must
            also provide the following information:
          </p>
          <ul>
            <li>The purpose of processing;</li>
            <li>Data category that interest;</li>
            <li>
              Recipients or categories of recipients to whom personal data are
              sent, in particular recipients from third countries (eg countries
              outside the EU/EEA) or international organizations;
            </li>
            <li>
              Where possible, the period for which data is stored, and if not
              possible, criteria used to determine this period;
            </li>
            <li>
              The right to request data rectification, erase or restrict the
              processing of the data that interest the person, to oppose the
              processing or to send a complaint to superior authorities:
            </li>
            <li>
              When personal data are not collected directly from the person in
              question, all information about their source;
            </li>
            <li>
              If automated decision-making or profiling processes take place
              and, if so, logic involved, about the significance and
              consequences of data processed in that way.
            </li>
          </ul>
          <p>
            At the request of the individual, <span lang="en">COMPETDAC</span>{" "}
            must provide a copy of the personal data being processed by
            following the steps below:
          </p>
          <ul>
            <li>
              <strong>Free copy:</strong> The first copy requested is free of
              charge;
            </li>
            <li>
              <strong>The following copies:</strong> Subsequent requests may
              apply a certain fee;
            </li>
            <li>
              <strong>Electronic copies:</strong> if the individual does not
              specify otherwise, the electronic copies will be provided in the
              usual format (.csv / .txt / .xml).
            </li>
          </ul>
          <p>
            Moreover, <span lang="en">COMPETDAC</span> will ensure that a
            person’s right to obtain a copy will not affect the rights and
            freedoms of another person.
          </p>
          <h2>4.3 Right to rectification</h2>
          <p>
            Individuals have the right to have inaccurate data corrected.
            Following a request for rectification,{" "}
            <span lang="en">COMPETDAC</span> will take reasonable steps to
            ensure that the data are accurate and rectified if necessary, taking
            into account the arguments and evidence presented by the person
            concerned.
          </p>
          <h2>4.4 The right to erasure or “the right to be forgotten”</h2>
          <p>
            This right guarantees individuals the possibility of deleting their
            personal data if they do not want to be processed or when there is
            no good reason for <span lang="en">COMPETDAC</span> to keep them.
          </p>
          <p>
            Cases in which individuals are entitled to delete personal data:
          </p>
          <ul>
            <li>
              Personal data are no longer required for the purpose for which
              they were originally collected or processed;
            </li>
            <li>
              The organization is based on the consent obtained as the legal
              basis for the possession of the data, but the individual withdraws
              this consent and there is no legal basis for processing;
            </li>
            <li>
              <span lang="en">COMPETDAC</span> is based on its legitimate
              interest as the processing base, but the individual opposes the
              processing of its data, and there is no longer any legitimate
              interest to continue to process;
            </li>
            <li>
              <span lang="en">COMPETDAC</span> processes personal data directly
              based on its legitimate interest and the individual opposes such
              processing;
            </li>
            <li>Personal data has been processed illegally;</li>
            <li>
              Personal data must be deleted to comply with a state member legal
              obligation.
            </li>
          </ul>
          <p>
            <span lang="en">COMPETDAC</span> will not provide data erasure if
            processing is required for one of the following reasons:
          </p>
          <ul>
            <li>
              For the exercise of the right to free expression and information;
            </li>
            <li>To comply with a legal obligation;</li>
            <li>
              To fulfill a task of public interest or by an official authority;
            </li>
            <li>
              For purposes of public, scientific, historical or statistical
              interest, where erasure makes it impossible to achieve the
              objectives of the processing;
            </li>
            <li>
              For the establishment, exercise and defense of legal interests.
            </li>
          </ul>
          <h2>4.5 The right to restrict the processing</h2>
          <p>
            An alternative to erasure is to restrict processing under certain
            circumstances.
          </p>
          <p>
            The right to restrict the processing can take place under the
            following conditions:
          </p>
          <ul>
            <li>
              The individuals in question dispute the correctness of their
              personal data and the Organization verifies this;
            </li>
            <li>
              Personal data has been illegally processed (eg, disregarding the
              first principle of GDPR) and the individual opposes wiping and
              calls for restriction;
            </li>
            <li>
              <span lang="en">COMPETDAC</span> no longer requires personal data,
              but the subject requires that they be retained in order to be able
              to establish, exercise or defend legitimate interests
            </li>
            <li>
              The individual opposed the processing of his data under Article 21
              (1) of the GDPR, based on the verification of the legitimate basis
              of <span lang="en">COMPETDAC</span> that exceeds that of the
              subject.
            </li>
          </ul>
          <h2>4.6 The right to portability</h2>
          <p>
            The individual has the right to obtain his personal data of
            interest, provided to <span lang="en">COMPETDAC</span>, in a
            structured, readable format.
          </p>
          <p>Acecst right can be guaranteed under the following conditions:</p>
          <ul>
            <li>
              The legal basis for the processing of personal data is also
              accepted for the performance of a contract; and
            </li>
            <li>
              The organization carries out the process by automatic means
              (without written documents)
            </li>
          </ul>
          <h2>4.7 The right to oppose</h2>
          <p>
            <span lang="en">COMPETDAC</span> will facilitate the right to
            opposition for:
          </p>
          <ul>
            <li>
              Processing based on legitimate interests or performing a task of
              public interest/exercise of official authority (including the
              creation of a profile) – in this case, only if{" "}
              <span lang="en">COMPETDAC</span> has no convincing processing
              arguments that go beyond the interests, rights and freedom of the
              subject or if <span lang="en">COMPETDAC</span> does not need this
              data to establish, exercise or defend a legal right;
            </li>
            <li>
              Direct marketing (including profiling) when there is a legitimate,
              unconscious interest; and
            </li>
            <li>
              Processing for scientific/historical and statistical purposes – in
              this case, unless the process is necessary for the performance of
              a task of public interest.
            </li>
          </ul>
          <h2>
            4.8 The right not be subject of an automatic decision making or
            profiling
          </h2>
          <p>
            A natural person has the right not to be the subject of a decision
            taken exclusively by an automated process, including profiling,
            which may produce legal effects on the subject or significantly
            affect it.
          </p>
          <p>
            <span lang="en">COMPETDAC</span> will only resolve exclusive
            automated decisions with significant legal effect or significantly
            affecting if the decision is:
          </p>
          <ul>
            <li>
              The need to start a contract within an organization and subject;
            </li>
            <li>Explicitly based on the consent of the person concerned:</li>
            <li>Authorized by law (eg to combat tax evasion or fraud).</li>
          </ul>
          <p>
            In the first two cases, such decisions may be taken if the
            Organization implements appropriate safeguards to protect the rights
            of the subject and his/her freedom and legitimate interests.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  )
}
